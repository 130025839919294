.meditation {
  background: linear-gradient(rgba(35, 35, 35, 0.4), rgba(35, 35, 35, 0.4)), url('https://assets.calm.com/1920x1024/b6b30478b8ff7bee469475f63e5f5247.jpeg');
  height: 100%;
  color: white;
  text-align: center;
}
.program {
    height: 190vw;
  }

.program 
  .program-title {
  padding: 2em 0;
}

.program 
  .author-info {
  padding: 1em 0 ;
}

.program 
  .author-info
    .author-img {
      width: 50px;
      height: 50px;
      border-radius: 5em;
}
.program 
  .program-content {
    width: 80%;
    margin: auto;
    max-width: 500px;
  }
.program 
  .program-content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
  }
.program 
  .program-content-num {
    width: 20px;
    height: 20px;
    background: lightgreen;
    border-radius: 2em;
    color: black;
    padding: 8px;
  }

