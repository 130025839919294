.nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  height: 65px;
  display: flex;
  justify-content: space-evenly;
  z-index: 2;
  box-shadow: 0 2px 1px rgba(0,0,0,0.09), 
              0 4px 2px rgba(0,0,0,0.09), 
              0 8px 4px rgba(0,0,0,0.09), 
              0 16px 8px rgba(0,0,0,0.09),
              0 32px 16px rgba(0,0,0,0.09);
}
.nav .nav-link:hover {
  color: lightgrey;
}

.nav .nav-link.active {
  color: orangered
}

.nav .icon-logo {
  font-size: 25px;
  font-family: Gill Sans, sans-serif;
}

.nav > span, .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: black;
}

