.scrollbar {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.scrollbar:-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

