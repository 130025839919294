
.app {
  height: auto;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  /* background: linear-gradient(to bottom right, #00C9FF, #92FE9D); */
  background: url('./images/atmosphere-background-bright-clouds-lg.jpg');
}
.app.app-login {
  position: absolute;
}

@media only screen and (max-width: 1539px){
  .app {
    min-height: 100vw;
  }
}

@media only screen and (max-width: 1279px){
  .app {
    min-height: 120vw;
  }
}

@media only screen and (max-width: 479px) {
  .app {
    min-height: 210vw;
  }
}

@media only screen and (max-width: 359px) {
  .app {
    min-height: 250vw;
  }
}