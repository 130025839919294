.home {
  height: auto;
  margin-top: 4em;
}
  .category {
    color: white;
    margin-top: 1em;
  }
  .category-title {
    margin-left: 2em;
    background: lightslategray;
    border-radius: 1em;
    padding: 3px 24px;
  }

  .category:last-child {
    padding-bottom: 4em;
  }

@media only screen and (max-width: 767px){
  .home 
    .category 
      .category-title {
        margin-left: 1em;
      } 
}
