.header {
  position: fixed;
  background: white;
  height: 35px;
  width: 100%;
  color: black;
  font-size: 20px;
  font-family: 'Cinzel', serif;
  text-align: center;
  z-index: 5;
  align-items: center;
  padding-top: 10px;
  box-shadow: 0 2px 1px rgba(0,0,0,0.09), 
              0 4px 2px rgba(0,0,0,0.09), 
              0 8px 4px rgba(0,0,0,0.09), 
              0 16px 8px rgba(0,0,0,0.09),
              0 32px 16px rgba(0,0,0,0.09);
  display: flex;
  justify-content: center;
}

.header > img {
  width: 30px;
  height: 30px;
  border-radius: 2em;
  position: absolute;
  right: 8px;
  top: 8px;
}