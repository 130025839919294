.login {
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 5;
  position: fixed;
  background: url('https://assets.calm.com/1920x1024/b6b30478b8ff7bee469475f63e5f5247.jpeg');
}

.login .hero {
  position: absolute;
  top: 7em;
  width: 100%;
  z-index: 2;
  height: 30px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login #firebaseui-auth-container {
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.login .firebaseui-list-item {
  margin-bottom: 1.5em;
}

.login .firebaseui-idp-button {
  border-radius: 2em;
}

.login .firebaseui-tos {
  color: white;
  margin-bottom: 4.5em;
}

.login .heading, .subheading {
  margin: auto;
  font-family: 'Cinzel', serif;
  text-align: center;
}

.login .heading {
  font-size: 60px;
}

.login .subheading {
  font-size: 20px;
}

.login .layer {
  background-color: rgba(35, 35, 35, 0.45);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
}

.login video {
  width: 100%;
  height: 200vw;
  object-fit: cover;
  position: fixed;
}

.login .fb-login-button {
  margin-top: 26em;
}