.profile {
  text-align: center;
  padding: 4em 0;
}

.profile
  .profile-bg {
  background: url('https://assets.calm.com/1920x1024/b6b30478b8ff7bee469475f63e5f5247.jpeg');
  height: 100%;
  width: 100%;
  filter: blur(30px);
  position: absolute;
  z-index: -1;
}

.profile
  .profile-img {
  width: 80px;
  height: 80px;
  border-radius: 2em;
}