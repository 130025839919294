.card-carousel {
  display: flex;
  width: 100%;
  margin: 1em 0; 
}

@media only screen and (max-width: 767px){
  .card-carousel {
    width: 767px;
  }
}

@media only screen and (max-width: 414px){
  .card-carousel {
    width: 414px;
  }
}