.tray {
  position: fixed;
  z-index: 1;
  width: 100%;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  bottom: 10em;
  background-size: cover;
  background-color: black;
  top: calc(90% - 165px);
}
.tray .tray-header {
    color: #ffffff;
  }
.tray .options {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 1em;
  }
.tray .tray-video {
    width: 102vw;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left:0;
  }
.tray.collapse {
  height: 70px;
  transform: translate(0em, 7em);
  transition-timing-function: ease-in;
  transition: 1s;
}
  /* expanded tray */
.tray.expanded { 
  top: -4.5em;
  border-radius: 0;
  height: 100%;
  transition-property: none;
  background-color: black;
  transform: translateY(7em);
  transition-timing-function: ease-in;
  transition: 1s;
}

.tray .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tray .content img {
  position: absolute;
  width: 50%;
  justify-content: center;
  max-width: 350px;
}
.tray audio {
  background-color: rgba(230, 230, 255, 1);
  width: 95%;
  margin: auto;
  background: transparent;
  position: absolute;
  bottom: 11em;
}
.tray .hide {
  display: none;
}

.tray
  audio::-webkit-media-controls-current-time-display {
  display: none;
}

.tray
  audio::-webkit-media-controls-time-remaining-display {
    display: none;
}

@media only screen and (max-width: 767px){
  .tray.collapse {
    transform: translate(0, 7em);
  }
}