:root {
  --media-medium: "only screen and (max-width: 767px)";
  --media-3xlarge: "only screen and (max-width: 1539px)";
  --media-2xlarge: "only screen and (max-width: 1279px)";
  --media-xlarge: "only screen and (max-width: 1023px)";
  --media-large: "only screen and (max-width: 989px)";
  --media-small: "only screen and (max-width: 479px)";
  --media-xsmall: "only screen and (max-width: 359px)";
  --media-landscape: "(max-width: 1023px) and (max-height: 767px) and (orientation: landscape)";
  /* // iPhone 5 and similar generation devices (current Android is 360+ and current iOS is 375+) */
  --primary-dark: #232323;
  --primary-light: #ffffff;
  --primary-orange: #e16120;
  --primary-teal: #3a9691;
  --secondary-orange: #fe7732;
  --dark-teal: #308580;
  --light-teal: #4ea19c;

  /* // darkest to lightest */
  --greyscale-1: #4a4a4a;
  --greyscale-2: #707070;
  --greyscale-3: #bababa;
  --greyscale-4: #e3e3e3;
  --greyscale-5: #f5f5f5;

  /* // font weights */
  --font-light:100;
  --font-normal: 400;
  --font-heavy: 700;

  /* // font sizes */
  --font-size-base: 16px;
  --font-size-inherit: 1em;
  --font-size-legal: 10px;
  --font-size-small: 14px;
  --font-size-smaller: 12px;
  --font-size-smallest: 11px;
  --font-size-big: 18px;
  --font-size-bigger: 20px;
  --font-size-biggest: 24px;
  --font-size-subtitle: 34px;
  --font-size-title: 40px;
  --font-size-title-big: 48px;
  --font-size-title-biggest: 64px;


 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.antialias {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

.hidden {
  display: none;
}

.inline {
  display: inline;
}

.cursor-pointer {
  cursor: pointer;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.font-bold {
  font-family: 'europa-boldregular, sans-serif';
  font-weight: 700;
}

.font-light {
  font-family: 'europa-lightregular, sans-serif';
  font-weight: '100';
}

.font-regular {
  font-family: 'europa-lightregular, sans-serif';
  font-weight: '400';
}

.font-italic {
  font-style: italic;
}

.no-border {
  border: 0;
}

.no-padding {
  padding: 0;
}

/* button */
button {
  border: 0;
  background: none;
}

.button {
  letter-spacing: 0;
  display: inline-block;
  outline: none;
  border: none;
  border-radius: 3em;
  box-sizing: border-box;
  position: relative;
  font-size: var(--font-size-small);
  text-decoration:  none;
  text-transform:  capitalize;
  white-space:  nowrap;
  text-align:  center;
  cursor:  pointer;
  transition:  all 200ms ease;
  padding:  11px 24px;
  background-color: var(--primary-light);
}
  
.btn-primary, .btn-light, .btn-secondary {
  height: 40px;
  padding: 0 32px;
}
  
a.btn-primary, a.btn-light, a.btn-secondary {
  line-height: 40px;
}

a {
  text-decoration: none;
}

.btn-primary {
  background-color: var(--primary-teal);
  color: var(--primary-light);
  border-radius: 4em;
}
  
.btn-secondary {
  background-color: transparent;
  color: var(--primary-teal);
  box-shadow: 0 0 0 2px var(--primary-teal);
  border-radius: 4em;
}

/* text  */

.h0-text {
  font-size: 64px;
  line-height: (72/64);
  letter-spacing: 2px;
}
.h1-text {
  font-size: 48px;
  line-height: (56/48);
  letter-spacing: 5px;
  letter-spacing: 2px
}
.h2-text {
  font-size: 40px;
  line-height: (48/40);
  letter-spacing: 5px;
  letter-spacing: 2px;
}
.h3-text {
  font-size: 34px;
  line-height: (42/34);
}
.h4-text {
  font-size: 24px;
  line-height: (32/24);
}
.h5-text {
  font-size: 16px;
  line-height:(24/16);
}
.p1-text {
  font-size: 18px;
  line-height: (30/18);
}
.p2-text {
  font-size: 16px;
  line-height: (26/16);
}
.p3-text {
  font-size: 14px;
  line-height: (24/14);
}
.micro-text {
  font-size: 12px;
  line-height: (12/12);
}
.micro-caps {
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.nano-text {
  font-size: 11px;
  line-height: 1;
}
.nano-sm-text {
  font-size: 9px;
  line-height: 1;
}

/* media-medium */
@media only screen and (max-width: 767px) {
  .h0-text {
    font-size: 40px;
    line-height: (48/40);
  }
  .h1-text {
    font-size: 34px;
    line-height: (42/34);
  }
  .h2-text {
    font-size: 24px;
    line-height: (32/24);
  }
  .h3-text {
    font-size: 22px;
    line-height: (30/22);
  }
  .h4-text {
    font-size: 20px;
    line-height: (28/20);
  }
  .h5-text {
    font-size: 14px;
    line-height: (24/14);
  }
}