.card {
  position: relative;
  height: 200px;
  width: 200px;
  margin: 2em;
  cursor: pointer;
}

.card .title {
  color: #FFFFff;
  position: absolute;
  bottom: 0px;
  text-align: center;
  background: rgba(40,40,40,0.8);
  width: 100%;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  height: 35px;
  margin: auto;
  font-size: 14px;
}

.card img {
  height: 200px;
  width: 200px;
  position: relative;
  margin: auto;
  object-fit: cover;
  border-radius: 1em;
}

.card > .title div {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  display: block;
  overflow: hidden;
  margin: auto;
  padding: 8px;
}

@media only screen and (max-width: 767px){
  .card {
    height: 150px;
    width: 150px;
    margin: 1em;
  }
  .card img {
    height: 150px;
    width: 150px;
  }

  .card .title div {
    width: auto;
  }
}